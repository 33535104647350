import { Dispatch } from 'redux';
import { ActionType } from 'store/actions/ActionType';
import { ThunkResult } from 'store/store';
import { getSGConnect } from 'core/services/common/sgConnect';
import {
    createPreferredLanguageFetchRequestAction,
    createPreferredLanguageFetchSuccessAction,
    createPreferredLanguageFetchFailureAction,
    createChangeAvailableLanguagesAction,
} from 'store/actions/languageAction';

export const changePreferredLanguage = (language: string): ThunkResult => {
    return async (
        dispatch: Dispatch<ActionType>,
        _state,
        { serviceBoardClient, logger },
    ) => {
        try {
            // for unkonwn reason, "sgwt-account-center" handle event "sgwt-account-center--language-changed" multiple times for one action
            // this is a security to avoid multiples calls
            if (_state().applicationContext.changePreferredLanguageIsFetching) {
                return;
            }

            dispatch(createPreferredLanguageFetchRequestAction());

            await serviceBoardClient.changePreferredLanguage(language);

            // preferredLanguage is stored in SGConnect claims
            // renew token to get updated claims in token
            const sgConnectPromise = new Promise<void>((resolve, reject) => {
                const autoRejectId = setTimeout(() => {
                    reject('Can\'t renew SGConnect authorization');
                }, 5000);

                getSGConnect().renewAuthorization(() => {
                    clearTimeout(autoRejectId);
                    resolve();
                });
            });

            await sgConnectPromise;

            dispatch(createPreferredLanguageFetchSuccessAction(language));

        } catch (error: unknown) {
            logger.error(error);
            dispatch(createPreferredLanguageFetchFailureAction());
        }
    };
};

export const changeAvailableLanguages = (languages: string[]): ThunkResult => {
    return async (
        dispatch: Dispatch<ActionType>,
    ) => {
        dispatch(createChangeAvailableLanguagesAction(languages));
    };
};